import React, { useEffect } from 'react';
import './App.css';

const App = () => {
  useEffect(() => {
    // Update the document title using the browser API
    if ('addEventListener' in window) {
      window.addEventListener('load', function () {
        document.getElementById('body-div').className = document
          .getElementById('body-div')
          .className.replace(/\bis-preload\b/, '');
      });
      document.getElementById('body-div').className +=
        navigator.userAgent.match(/(MSIE|rv:11\.0)/) ? ' is-ie' : '';
    }
  });

  return (
    <div id='body-div' className='body-class is-preload'>
      <div id='wrapper'>
        <section id='main'>
          <header>
            <span className='avatar'>
              <img id='avatarId' src='images/me.jpg' alt='' />
            </span>
            <h1>Drake Nelson</h1>
            <p>React Web Applications</p>
          </header>
          <footer>
            <ul className='icons'>
              <li>
                <a
                  href='https://dashboard.drakenelson.com'
                  className='notext fa-user'>
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href='mailto: drake@drakenelson.com'
                  className='fa-mail-reply notext'>
                  Email
                </a>
              </li>
              <li>
                <div>
                  <a href='https://washburnsmetal.com'>
                    <img className='imageicon' src='images/wagen.png' alt='' />
                  </a>
                </div>
              </li>
            </ul>
          </footer>
        </section>
        <footer id='footer'>
          <ul class='copyright'>
            <li>
              <a href='https://www.drakenelson.com'>&copy; Drake Nelson</a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
};

export default App;
